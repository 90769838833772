@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .btn-secondary {
        @apply bg-secondary rounded-full px-6 py-3 text-white capitalize;
    }

    .select_floating {
        @apply p-4 pe-9 block w-full bg-white border border-gray40 rounded-xl text-sm focus:ring-0 font-medium cursor-pointer focus:pt-6 focus:pb-2 [&:not(:placeholder-shown)]:pt-5 [&:not(:placeholder-shown)]:pb-2 autofill:pt-6 autofill:pb-2;
    }

    .floating_label {
        @apply absolute top-3 left-4 truncate pointer-events-none transition ease-in-out duration-100 peer-focus:text-xs peer-focus:-translate-y-1.5 peer-focus:text-gray80 peer-[:not(:placeholder-shown)]:text-[10px] peer-[:not(:placeholder-shown)]:-translate-y-1.5 peer-[:not(:placeholder-shown)]:text-gray80;
    }
}

/* .layout_main {
    height: calc(100vh - 80px);
    overflow-x: auto;
} */
.select_floating:focus-visible {
    outline: none;
}
.fwpo_select__control {
    min-width: 120px;
}

.fwpo_select__control .fwpo_select__value-container {
    padding: 0;
    /* padding-left: 5px; */
}

.fwpo_select__indicators .fwpo_select__indicator-separator {
    display: none;
}

.fwpo_select__control .fwpo_select__indicator {
    padding: 2px;
}

.fwpo_select__menu .fwpo_select__option {
    cursor: pointer;
}

.react-tel-input {
    height: 44px;
}

.react-tel-input .form-control {
    height: 44px;
    border-radius: 12px;
    width: 100%;
}

.react-tel-input .flag-dropdown {
    border-radius: 12px 0 0 12px;
    background-color: #fff;
}

.employee_modal_input .react-tel-input .flag-dropdown {
    border: none;
    padding: 14px 0 !important;
}

.employee_modal_input .react-tel-input .flag-dropdown .selected-flag {
    border-right: 1px solid black;
}

.employee_modal_input .react-tel-input .form-control {
    border: none;
}

.react-tel-input .selected-flag:hover {
    background-color: unset;
}

.react-tel-input .flag-dropdown.open .selected-flag,
.react-tel-input .flag-dropdown.open {
    border-radius: 12px 0 0 12px;
}
.employEditPhoneInput {
    width: 100% !important;
    background-color: #eff0f1 !important;
}
.employEditPhoneButton {
    background-color: #eff0f1 !important;
}

.p-component::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
    background: #efefef;
}
.p-component::-webkit-scrollbar-thumb {
    background: #dedede;
    border-radius: 10px;
}

.language_radio input {
    cursor: pointer;
}

.language_radio input:checked[type='radio'] {
    accent-color: black;
}

.busy_date {
    font-size: 14px;
}

.busy_date::placeholder {
    font-size: 12px;
}

.busy_date:focus-visible {
    outline: none;
}

.employee_tab_active {
    color: black;
    position: relative;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.02rem;
    /* border-bottom: 1px solid black; */
}

.employee_tab_active::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -16px;
    left: 0;
    background-color: black;
}

.job_dropdown .fwpo_select__control {
    border-radius: 12px;
    min-height: 100%;
    cursor: pointer;
    padding: 15px 6px 2px;
    border-color: #bfc3c6;
}

.job_dropdown .fwpo_select__indicator {
    color: #80868d;
    margin-top: -10px;
}

.job_dropdown .fwpo_select__indicator:hover {
    color: #80868d;
}

.job_dropdown .fwpo_select__menu {
    background-color: black;
}

.job_dropdown .fwpo_select__menu-list::-webkit-scrollbar {
    width: 3px;
    border-radius: 10px;
    background: transparent;
}

.job_dropdown .fwpo_select__menu-list::-webkit-scrollbar-thumb {
    background: #828282;
    border-radius: 10px;
}

.job_dropdown .fwpo_select__option {
    color: white;
    display: flex;
    align-items: center;
    font-size: 14px;
}

/* .job_dropdown .fwpo_select__option::before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 5px;
    background-color: #36b845;
} */

.job_dropdown .fwpo_select__option:active {
    background-color: transparent;
}

.job_dropdown .fwpo_select__option--is-focused {
    background-color: transparent;
}

.job_dropdown .fwpo_select__option--is-selected {
    background-color: black;
}
/* .schedule-custom.schedular_dt .scheduler{
    width: calc(100%) !important;;
}
.schedule-custom.schedular_dt .scheduler-view div{
    width: 100% !important;;
}
.schedule-custom.schedular_dt .scheduler .header3-text{
    width: 14.28% !important;
}
.schedule-custom.schedular_dt .scheduler-view{
    width: calc(100%) !important;
}
.schedule-custom .event-container >div:empty{
    display: none;
}
.schedule-custom .scheduler-bg-table{
    width: 100% !important;
}
.schedule-custom .scheduler-bg-table td{
    width: 14.28% !important;
} */
.schedular_dt {
    position: relative;
}
.schedular_dt .scheduler {
    margin: 0;
    /* position: relative; */
}

.schedular_dt .scheduler-view {
    border: none;
    margin-top: -30px;
}
.schedular_dt .scheduler-view > div {
    scrollbar-width: none;
}
.schedular_dt .scheduler-view .header3-text {
    /* border-radius: 8px; */
    border: unset;
}

.schedular_dt .scheduler-view .header_first {
    border-radius: 8px 0px 0px 8px;
}
.schedular_dt .scheduler-view .header_last {
    border-radius: 0px 8px 8px 0px;
}
.schedular_dt .scheduler-view .header_single {
    border-radius: 8px;
}

.schedular_dt .scheduler-bg .scheduler-bg-table tr {
    height: 88px !important;
}
table.resource-table th,
table.scheduler-table th,
table.resource-table td,
table.scheduler-bg-table td,
table.scheduler-table td {
    border-left: 1px solid #e9e9e9;
}
.schedular_dt .scheduler-content .event-container {
    height: 88px !important;
}

.schedular_dt .scheduler-content .timeline-event {
    height: 88px;
    padding: 4px 0 6px;
    padding-right: 15px;
}

.schedular_dt .scheduler-content .timeline-event:has(.round-tail),
.schedular_dt .scheduler-content .timeline-event:has(.round-all) {
    padding-right: 0;
}
.schedule-custom.schedular_dt .scheduler-content .timeline-event {
    padding-right: 4px;
    padding-left: 4px;
}
.schedular_dt .scheduler-content .timeline-event .event-item {
    height: 100% !important;
    display: flex;
    align-items: center;
    border-radius: 8px;
}

.schedular_dt .ant-col {
    /* width: 1588px; */
    position: unset;
}

.schedular_dt .ant-radio-group {
    display: none;
}

.schedular_dt .anticon-right {
    right: 1rem;
}

.schedular_dt .anticon-right,
.schedular_dt .anticon-left {
    top: 6px;
    color: #b6b6b6 !important;
    z-index: 99999;
}
.schedular_dt .icon-nav svg {
    font-size: 16px;
}

.schedular_dt .header2-text-label {
    display: none;
}

.job-calender {
    scrollbar-width: none;
}

.job-calender::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
    background: white;
}
.job-calender::-webkit-scrollbar-thumb {
    background: #dedede;
    border-radius: 10px;
}

.add_job_dropdown ul::-webkit-scrollbar {
    width: 3px;
    border-radius: 10px;
    background: black;
}
.add_job_dropdown ul::-webkit-scrollbar-thumb {
    background: #828282;
    border-radius: 10px;
}

.popup-time-picker .react-time-picker__wrapper {
    border: none;
}

.popup-time-picker .react-time-picker__inputGroup__input:focus-visible {
    outline: none;
}
.popup-time-picker .react-time-picker__inputGroup {
    font-size: 16px;
    text-align: end;
}

.popup-time-picker .react-time-picker__inputGroup select {
    appearance: none;
}

.job_history::-webkit-scrollbar-thumb,
.time_sheet_left::-webkit-scrollbar-thumb {
    background: #dedede;
    border-radius: 10px;
}

.job_history::-webkit-scrollbar,
.time_sheet_left::-webkit-scrollbar {
    width: 2px;
    border-radius: 10px;
    background: white;
}

.finish_job {
    min-width: 35% !important;
    padding: 24px 0;
}

.finish_modal_inner::-webkit-scrollbar-thumb {
    background: #dedede;
    border-radius: 10px;
}

.finish_modal_inner::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
    background: white;
}

/* .employee_table .p-datatable-tbody {
    max-height: 480px;
    overflow-y: auto;
} */

.datatable_pagination .p-highlight {
    color: black;
    font-weight: 600;
}
.datatable_pagination_sheduler {
    min-height: 0px !important;
    background-color: #f6f6f8 !important;
}
.datatable_pagination_joblist {
    min-height: 0px !important;
}
.logout_modal {
    min-width: 30% !important;
}

/* .choose_employee .schedular_dt .anticon-right {
    right: 2.5rem !important;
} */
.monthly-chart canvas {
    width: 100% !important;
}
.sidebar_date button {
    border-radius: 12px;
    background-color: #eff0f1;
    padding: 8px 20px;
}

@media (min-width: 1400px) {
    .logout_modal {
        min-width: 20% !important;
    }
}

.star-review .star-ratings .star-container .widget-svg {
    stroke: #627BE8;
    stroke-width: 1px;
  }