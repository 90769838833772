@import '../fonts/stylesheet.css';

body {
    font-family: 'Satoshi';
    color: #000;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
}

.p-component {
    font-family: 'Satoshi';
    font-style: normal;
}

.btn-shadow {
    box-shadow: 0px 4px 12px 0px rgba(98, 123, 232, 0.3);
}

.main-shadow {
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
}
.jobdatatable thead tr th:nth-child(2) {
    padding-left: 50px;
}
.jobdatatable tbody tr td:nth-child(2) {
    padding-left: 50px;
}
.logdatatable tbody tr {
    background-color: transparent;
    cursor: pointer;
}

.logdatatable tbody tr td {
    background-color: #fff;
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    padding: 16px 24px;
}

.logdatatable tbody tr td:first-child {
    border-radius: 16px 0 0 16px;
}

.logdatatable tbody tr td:last-child {
    border-radius: 0 16px 16px 0;
}

.logdatatable thead tr {
    background-color: transparent;
}

.logdatatable thead tr th {
    background-color: #eff0f1;
    font-weight: 500;
    font-size: 14px;
    color: #606870;
    padding: 16px 24px;
}

.logdatatable thead tr th:first-child {
    border-radius: 16px 0 0 16px;
}

.logdatatable thead tr th:last-child {
    border-radius: 0 16px 16px 0;
    width: 150px;
}

.logdatatable {
    border-collapse: separate;
    border-spacing: 0 10px;
}

.workerdatatable {
    border-collapse: collapse;
    border-spacing: 0 0;
}

.workerdatatable tbody tr {
    border-bottom: 1px solid #e5e7eb;
}

.workerdatatable tbody tr:last-child {
    border-bottom: none;
}
.workerdatatable thead tr th {
    padding: 8px 14px;
}

.workerdatatable tbody tr td {
    padding: 12px 14px 8px 14px;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.p-datatable-tbody tr {
    box-shadow: 0px 4px 8px 0px rgba(141, 141, 141, 0.05);
}

.custom-datepicker {
    border: none !important;
}

/* CustomDatePicker.css */

.custom-datepicker .react-datepicker__day--keyboard-selected {
    border-radius: 40px;
    background-color: #000;
    color: #fff;
}
.custom-datepicker .react-datepicker__day-name,
.react-datepicker__day {
    width: 52px;
    line-height: 44px;
    font-size: 14px;
}
.custom-datepicker .react-datepicker__day--in-selecting-range {
    background-color: #eff0f1;
    color: #606870;
}
.custom-datepicker .react-datepicker__day--in-range,
.react-datepicker__day--selected {
    border-radius: 40px;
    background-color: #000;
    color: #fff;
}
.react-datepicker__day--selected:hover {
    border-radius: 40px;
    background-color: #000;
    color: #fff;
}
.custom-datepicker .react-datepicker__header {
    background-color: #fff;
    border-bottom: none;
}

.custom-datepicker .react-datepicker__month-container .react-datepicker__header {
    text-align: left;
}
.custom-datepicker .react-datepicker__navigation {
    top: 9px;
}

/* .react-datepicker__month-container
    .react-datepicker__header
    .react-datepicker__current-month {
    margin-left: 35px;
} */

/* Style for the second .react-datepicker__month-container */
.custom-datepicker .react-datepicker__month-container + .react-datepicker__month-container .react-datepicker__header {
    text-align: right;
}

.react-datepicker__month-container + .react-datepicker__month-container .react-datepicker__header .react-datepicker__current-month {
    margin-left: 0px;
    margin-right: 35px;
}

.schedular_dt .scheduler > tbody > tr > td:first-child {
    display: none;
}
.schedular_dt > .scheduler {
    width: 100% !important;
}
.schedular_dt .anticon {
    position: absolute;
    top: 4rem;
}
.schedular_dt .anticon-left {
    left: 1rem;
}
.react-datepicker-popper {
    padding-bottom: 0 !important;
}

.sort-custom-datepicker .react-datepicker__children-container {
    margin: 0;
}
.sort-custom-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day {
    width: 29px;
    line-height: 29px;
    padding: 0;
    color: #dfe1e2;
    font-weight: 300;
}

.sort-custom-datepicker {
    background-color: black;
}

.sort-custom-datepicker .react-datepicker__header {
    background-color: black;
    border-bottom: none;
}

.sort-custom-datepicker .react-datepicker__current-month {
    color: #dfe1e2;
    font-weight: 400;
}

.sort-custom-datepicker .react-datepicker__day-name {
    color: #dfe1e2;
}

.sort-custom-datepicker .react-datepicker__day:hover {
    background-color: black;
}
.sort-custom-datepicker .react-datepicker__day--selected,
.sort-custom-datepicker .react-datepicker__day--in-range,
.sort-custom-datepicker .react-datepicker__day--in-range:hover {
    background-color: #627be8 !important;
    border-radius: 50%;
}

.sort-custom-datepicker .react-datepicker__day--keyboard-selected {
    background-color: black;
}

.sort-custom-datepicker .react-datepicker__navigation-icon::before {
    top: 12px;
}

.sort-custom-datepicker .job-filter-btn {
    width: 100%;
    justify-content: space-between;
}

.sort-custom-datepicker .job-filter-btn button {
    border-color: #666666;
    color: white;
}

.sort-custom-datepicker .job-filter-btn-inner button {
    width: 100px;
}

.sort-custom-datepicker .job-filter-btn-inner button div {
    font-weight: 300;
}

.sort-custom-datepicker .react-datepicker__children-container {
    width: 100%;
}

.dashboard_dropdown .fwpo_select__control {
    cursor: pointer;
    padding: 5px 16px;
    
}

.dashboard_dropdown_responsive .fwpo_select__control {
    cursor: pointer !important;
    padding: 5px 8px !important;
    min-width: 85px !important;
   
}

.dashboard_dropdown .fwpo_select__menu {
    background-color: black;
}

.dashboard_dropdown .fwpo_select__option {
    color: white;
}

.dashboard_dropdown .fwpo_select__option--is-focused,
.dashboard_dropdown .fwpo_select__option--is-selected,
.dashboard_dropdown .fwpo_select__option:active {
    background-color: #393939;
}

.job_creation_calendar .react-datepicker__current-month {
    margin-left: 40px !important;
    margin-right: 40px !important;
    font-weight: 700;
}

.job_creation_calendar .react-datepicker__navigation--previous {
    left: 22px;
}
.job_creation_calendar .react-datepicker__navigation--next {
    right: 22px;
}

.job_creation_calendar .react-datepicker__navigation-icon::before {
    border-width: 2px 2px 0 0;
    border-color: black;
}

.job_creation_calendar .react-datepicker__navigation-icon--previous::before {
    right: 8px;
}

.job_creation_calendar .react-datepicker__navigation-icon--next::before {
    left: 8px;
}

.job_creation_calendar .react-datepicker__month-container {
    margin-right: 30px;
}

.job_creation_calendar .react-datepicker__month-container:last-child {
    margin: 0;
}

.job_creation_calendar .react-datepicker__day--selected {
    background-color: black;
    color: white;
}

.job_creation_calendar .react-time-picker__wrapper {
    border: none;
}

.react-time-picker__inputGroup__input:invalid {
    background: white !important;
}

.job_creation_calendar .react-time-picker__inputGroup__input:focus-visible {
    outline: none;
}

/* .job_creation_calendar .react-datepicker__day--keyboard-selected {
    background-color: black;
    color: white;
    border-radius: 40px;
} */
.onlyTwoDate .slick-track {
    width: 256px !important;
}
.onlyOneDate .slick-track {
    width: 130px !important;
}
.time-sheeet-slider .slick-track {
    display: flex;
}
.time-sheeet-slider .slick-track .slick-slide > div > div {
    border: 1px solid transparent;
    width: 127px !important;
    border-radius: 12px;
    transition: all 0.3s ease;
}
.time-sheeet-slider .slick-track .slick-slide.slick-current {
    border: 1px solid black;
    background-color: black;
    color: white;
    border-radius: 8px;
}

.time-sheeet-slider .slick-prev::before {
    content: '';
    display: block;
    background-image: url('../svgs/lessthanIcon.svg');
    background-size: 100% 100%;
    width: 10px;
    height: 10px;
    filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7500%) hue-rotate(142deg) brightness(91%) contrast(105%);
}

.time-sheeet-slider .slick-next::before {
    content: '';
    display: block;
    background-image: url('../svgs/lessthanIcon.svg');
    background-size: 100% 100%;
    width: 10px;
    height: 10px;
    filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7500%) hue-rotate(142deg) brightness(91%) contrast(105%);
    transform: rotate(180deg);
}

.time-sheeet-slider .slick-slide {
    cursor: pointer;
}

.react-time-picker--disabled {
    background-color: transparent !important;
}
.home_icon svg path {
    stroke: black;
}
.your_jobs .p-component {
    /* max-height: calc(100vh - 224px); */
    overflow: auto;
    scrollbar-width: none;
    /* min-height: 300px; */
}
.p-datatable-emptymessage td {
    text-align: center;
}
.emptyMessageShow {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* .modal-date-picker .react-datepicker__tab-loop{
    width: 0;
}
.modal-date-picker .react-datepicker-popper{
    position: relative !important;
    transform: none !important;
    left: 0 !important;
    top: -7px !important;
}
.modal-date-picker .modal-date-picker-start .react-datepicker-popper{
   left: -121px !important;
} */
.custom_react_select .fwpo_select__menu-list {
    max-height: unset;
}
.custom_react_select .fwpo_select__option {
    padding: 4px 12px;
}
.modal_fullview {
    height: 100%;
    width: 100%;
    background-color: #000 !important;
}
.admintable thead tr th:last-child {
    text-wrap: nowrap;
}
.mobile_datepicker_responsive .react-datepicker-wrapper {
    width: 49%;
}
.tablet_datepicker_responsive .react-datepicker-wrapper {
    width: 49%;
}

@media (max-width: 1279px) {
    .custom-datepicker {
        border: none !important;
        width: 100%;
    }
    .react-datepicker__month-container {
        width: 100%;
    }
    .react-datepicker__day-names {
        margin-top: 10px;
    }
    /* CustomDatePicker.css */

    .custom-datepicker .react-datepicker__day--keyboard-selected {
        border-radius: 40px;
        background-color: #000;
        color: #fff;
    }
    .react-datepicker__day-names {
        width: 100%;
    }
    .custom-datepicker .react-datepicker__day-name,
    .react-datepicker__day {
        width: 13%;
        line-height: 44px;
        font-size: 14px;
    }
    .custom-datepicker .react-datepicker__day--in-selecting-range {
        background-color: #eff0f1;
        color: #606870;
    }
    .custom-datepicker .react-datepicker__day--in-range,
    .react-datepicker__day--selected {
        border-radius: 40px;
        background-color: #000;
        color: #fff;
    }
    .custom-datepicker .react-datepicker__header {
        background-color: #fff;
        border-bottom: none;
    }

    .custom-datepicker .react-datepicker__month-container .react-datepicker__header {
        text-align: center;
    }
    .custom-datepicker .react-datepicker__navigation {
        top: 9px;
    }

    .job_creation_calendar .react-datepicker__current-month {
        margin-left: 0px !important;
        margin-right: 0px !important;
        font-weight: 700;
    }

    .job_creation_calendar .react-datepicker__navigation--previous {
        left: 0px;
    }
    .job_creation_calendar .react-datepicker__navigation--next {
        right: 0px;
    }

    .job_creation_calendar .react-datepicker__navigation-icon::before {
        border-width: 2px 2px 0 0;
        border-color: black;
    }

    .job_creation_calendar .react-datepicker__navigation-icon--previous::before {
        right: 0px;
    }

    .job_creation_calendar .react-datepicker__navigation-icon--next::before {
        left: 0px;
    }

    .job_creation_calendar .react-datepicker__month-container {
        margin-right: 0px;
    }

    .job_creation_calendar .react-datepicker__month-container:last-child {
        margin: 0;
    }

    .job_creation_calendar .react-datepicker__day--selected {
        background-color: black;
        color: white;
    }

    .job_creation_calendar .react-time-picker__wrapper {
        border: none;
    }

    .job_creation_calendar .react-time-picker__inputGroup__input:focus-visible {
        outline: none;
    }
}
